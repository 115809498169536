import { contactEmail } from '../../utils/constants';
import './Contact.css';
import { useNavigate } from "react-router-dom";

function Contact() {

    const navigate = useNavigate();

    return (
        <div className='container'>
            <div className="mdt-contact-container">
                <h1>Contact</h1>
                <p>For questions, concerns, feedback, suggestions, bug reports, feature requests, or just to say hi, please send us an email at: <a href={"mailto:" + contactEmail} className="link-style" target="_blank">{contactEmail}</a></p>
            </div>
        </div>
    );
}

export default Contact;
