import './Home.css';
import { Link, useNavigate } from "react-router-dom";

function Home() {

    const navigate = useNavigate();

    return (
        <div className='container'>
            <div className="mdt-home-container">

                <h1>Welcome to Middledot Tech!</h1>

                <p>We use technology, common sense, and creativity to create practical solutions to common problems that are fun and easy to use.</p>

                <p>We use a deliberate approach with four steps to create apps for you:</p>

                <ul>
                    <li><b>Dream:</b> What could we make that people would love to use and get a lot of value from?</li>
                    <li><b>Design:</b> From the big picture to the tiny details, we think deeply about what makes a solution great before writing any code.</li>
                    <li><b>Develop:</b> Transform the design into a fully functional app and test it rigorously.</li>
                    <li><b>Deploy:</b> Get the app into the hands of users like you!</li>
                </ul>

                <p><Link to="/interval-timer-unlimited-app" className='link-style'>Click here to learn more about our first app <i>Interval Timer Unlimited</i>.</Link></p>
                
            </div>
        </div>
    );
}

export default Home;
