import './PageNotFound.css';
import { useNavigate } from "react-router-dom";

function PageNotFound() {

    const navigate = useNavigate();

    return (
        <div className="mdt-pagenotfound-container">
            404
        </div>
    );
}

export default PageNotFound;
