import './App.css';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Home from './pages/home/Home';
import IntervalTimerUnlimitedApp from './pages/interval-timer-unlimited/IntervalTimerUnlimitedApp'
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import Contact from './pages/contact/Contact'
import PageNotFound from './pages/page-not-found/PageNotFound';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Header />
                <div className='mdt-app-container'>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/interval-timer-unlimited-app' element={<IntervalTimerUnlimitedApp />} />
                        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                        <Route path='/contact' element={<Contact />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </div>
                <Footer />
            </BrowserRouter>
        </div>
    );
}

export default App;
