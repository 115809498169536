import { useEffect } from 'react';
import './IntervalTimerUnlimitedApp.css';
import { useNavigate } from 'react-router-dom';

function PrivacyPolicy() {

    const navigate = useNavigate();

    return (
        <div className='container'>
            <div className="mdt-intervaltimerunlimitedapp-container">
                <h1><i>Interval Timer Unlimited</i> App</h1>

                <p><i>Interval Timer Unlimited</i> is a simple, powerful, and highly customizable interval timer app that gives you complete control of your workout structure!</p>

                <p>Quickly and easily create your perfect routine for workouts, HIIT, running, stretching & yoga, and any other interval workout.</p>

                <p>Create routines of any length and complexity. Put as many timers as you want into a routine.</p>

                <p>Each timer has a name, duration, and custom text-to-speech voice prompt or sound.</p>

                <p>You can also nest routines inside of routines–-with as many layers as you want. There are no limits, restrictions, or pre-set structures!</p>

                <h3>Main Features</h3>

                <ul>
                    <li><b>Spoken Instructions:</b> custom voice prompts guide you through your routine using text-to-speech</li>
                    <li><b>40+ Tones:</b> if you prefer sounds instead of voice prompts</li>
                    <li><b>Dark Mode:</b> easy on the eyes and better for your battery life (light mode also available)</li>
                    <li><b>16 Beautiful Color Schemes:</b> 8 for light mode and 8 for dark mode</li>
                    <li><b>Drag & Drop:</b> Easily rearrange your timers and routines</li>
                    <li><b>Skip or Backtrack:</b> Just like a music playlist, you can skip forward or backward in your routines</li>
                    <li><b>Quality of Life Features:</b> Sort, pin, duplicate, delete, and recover deleted routines (within 7 days)</li>
                    <li><b>Available For:</b> iOS and Android</li>
                </ul>

                <h3>Screenshots</h3>

                <p><b>Playing A Routine: </b>The screenshot below shows a routine in progress. The name of the routine, "Morning Cardio" is shown at the top. Below the name on either side you can see where you are in the routine: "3/12 timers" and "1/2 rounds" (6 timers in the routine multiplied by 2 rounds equals 12 timers total). Inside the blue countdown circle you have the name of the current timer, "Burpees", the time remaining for that timer, "27.79" seconds, and the total time remaining for the routine, "10:57". Near the bottom you can see the next timer in the routine is called "Rest" and lasts for 30 seconds. Below that you have control buttons.</p>

                <div className="mdt-screenshot-container">
                    <img src={require('../../assets/1-ios-iphone-14-pro-max-routine-play.png')} className='screenshot' alt='iPhone 14 Pro Max Routine Play Screenshot' />
                </div>

                <p><b>Light VS Dark Mode: </b>The screenshot below is the same as the one above but with a red color scheme. The screenshot has been edited to show a split between light and dark mode.</p>

                <div className="mdt-screenshot-container">
                    <img src={require('../../assets/2-ios-iphone-14-pro-max-light-dark-mode.png')} className='screenshot' alt='iPhone 14 Pro Max Light And Dark Mode Screenshot' />
                </div>

                <p><b>Creating A Timer: </b>The screenshot below shows creating the "Burpees" timer from the routine shown in the above two screenshots. Below the name field you can see the duration of the timer is 30 seconds. Below that are five "quick add" buttons that allow you to adjust the duration with a single tap. (The duration of these buttons can be customized in the settings.) Every timer can have a custom sound: either 1) a tone, or 2) a phrase to be spoken with text to speech, in this case "Do burpees for thirty seconds". The phrase is spoken when the timer starts, so use the phrase to tell yourself what to do while the timer plays.</p>

                <div className="mdt-screenshot-container">
                    <img src={require('../../assets/3-ios-iphone-14-pro-max-custom-voice-prompts.png')} className='screenshot' alt='iPhone 14 Pro Max Custom Voice Prompts Screenshot' />
                </div>

                <p><b>Drag & Drop: </b>The screenshot below shows rearranging timers in a routine. Simply tap, hold, and drag the handle (☰) on the right of a timer row to move it. At the bottom you can see the routine settings. You can add a new routine or timer, adjust the number of rounds a routine has, or set a routine to play infinitely (until you stop it).</p>

                <div className="mdt-screenshot-container">
                    <img src={require('../../assets/4-ios-iphone-14-pro-max-drag-and-drop.png')} className='screenshot' alt='iPhone 14 Pro Max Drag And Drop Screenshot' />
                </div>

                <p><b>16 Beautiful Color Schemes: </b>The screenshot below shows the color schemes available for light mode. Dark mode also has 8 color schemes.</p>

                <div className="mdt-screenshot-container">
                    <img src={require('../../assets/5-ios-iphone-14-pro-max-color-schemes.png')} className='screenshot' alt='iPhone 14 Pro Max Color Schemes Screenshot' />
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
