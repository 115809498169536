import { companyName, contactEmail, intervalTimerAppName } from '../../utils/constants';
import './PrivacyPolicy.css';
import { useNavigate } from "react-router-dom";

function PrivacyPolicy() {

    const navigate = useNavigate();
    const lastUpdatedDateString = 'September 16th, 2023'

    return (
        <div className='container'>
            <div className="mdt-privacypolicy-container">
                <h1>{intervalTimerAppName} Privacy Policy</h1>

                <i>This Privacy Policy was last updated on {lastUpdatedDateString}.</i>

                <p>{companyName} ("us", "we", or "our") operates the "{intervalTimerAppName}" application ("this app").</p>

                <h2>The Purpose of This Document</h2>

                <p>The purpose of this document is to inform you about the data or personal information collected by this app, how that data or personal information is stored and processed, who that data or personal information is shared with, and what control you have over that data or personal information.</p>

                <h2>Information Collection, Use, and Sharing</h2>

                <p>We don’t collect any personal data. We believe the best way to keep data safe is to never collect it at all. This app is designed such that no data or personal information is collected:</p>

                <ul>
                    <li>We do not require user accounts or registration.</li>
                    <li>We never ask for any personally identifying information such as name, email, phone number, address, or location.</li>
                    <li>All app data (such as routines and settings) are stored locally on your device. We do not have access to this data.</li>
                    <li>The app does not require an internet connection.</li>
                    <li>The only permission we request is for notifications (to alert you about routine progress or to send other important notices), which does not require or provide access to any personally identifying information.</li>
                </ul>

                <p>We do not collect any data or personal information and as a result we do not store or process your data or personal information, or share your data or personal information with anyone.</p>

                <h2>Changes To our Privacy Policy</h2>

                <p>We will update our Privacy Policy occasionally. Updates to our Privacy Policy will be posted on this page. You are advised to review our Privacy Policy occasionally for any changes. Changes to our Privacy Policy are effective when they are posted on this page. We will not change this Privacy Policy to allow the collection of your personal information or make other significant changes without your consent.</p>

                <h2>Contact Us</h2>

                <p>For questions or concerns about our Privacy Policy please send us an email at: <a href={"mailto:" + contactEmail} className="link-style" target="_blank">{contactEmail}</a></p>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
