import './Header.css';
import { useLocation, useNavigate } from "react-router-dom";
import logo from '../../assets/logo.png';
import { useEffect } from 'react';

function Header() {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="mdt-header-container">
            <a href="/">
                <img src={logo} className='logo' alt='Middledot Tech logo' />
            </a>
            <a href="interval-timer-unlimited-app" className='menuItem'>Interval Timer Unlimited App</a>
        </div>
    );
}

export default Header;
