import './Footer.css';
import { useNavigate } from "react-router-dom";

function Footer() {

    const navigate = useNavigate();

    return (
        <div className="mdt-footer-container">
            <a href="privacy-policy" className='menuItem'>Privacy Policy</a>
            <a href="contact" className='menuItem'>Contact</a>
        </div>
    );
}

export default Footer;
